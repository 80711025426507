import { useEffect, useState } from 'react';

import CopilotIcon from './CopilotIcon';

const LoadingMessage = () => {
  const [loadingText, setLoadingText] = useState('Thinking');

  const loadingStates = [
    'Thinking',
    'Still thinking',
    'Working on it',
    'Almost there',
    'Analyzing context',
    'Crafting response',
    'Bear with me',
    'Processing your request',
  ];

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % loadingStates.length;
      setLoadingText(loadingStates[currentIndex]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex max-w-full items-center justify-start gap-2'>
      <div className='flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-primary'>
        <CopilotIcon className='h-5 w-5' />
      </div>
      <div className='flex flex-row items-center gap-1 rounded-lg'>
        <span className='text-sm text-primary'>{loadingText}</span>
        <div className='flex gap-1'>
          <div className='animate-bounce text-primary [animation-delay:-0.3s]'>
            .
          </div>
          <div className='animate-bounce text-primary [animation-delay:-0.15s]'>
            .
          </div>
          <div className='animate-bounce text-primary'>.</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMessage;
