import { useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { AwardIcon } from 'lucide-react';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  createCustomerTag,
  deleteCustomerTag,
  updatCustomerTag,
} from '@/hooks/inbox';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

// Predefined colors for tags
const TAG_COLORS = [
  '#94A3B8', // slate
  '#EF4444', // red
  '#F97316', // orange
  '#F59E0B', // amber
  '#22C55E', // green
  '#06B6D4', // cyan
  '#3B82F6', // blue
  '#A855F7', // purple
  '#EC4899', // pink
];

const DEFAULT_TAG_COLOR = TAG_COLORS[0];

const ColorPickerTrigger = ({ colour }) => (
  <div className='flex h-10 items-center gap-2 rounded-md border border-input bg-background px-3 text-sm hover:bg-accent'>
    <div className='h-4 w-4 rounded-full' style={{ backgroundColor: colour }} />
  </div>
);

const ColorPicker = ({ selectedColor, onColorSelect }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <button className='flex items-center rounded-md hover:opacity-80'>
        <ColorPickerTrigger colour={selectedColor} />
      </button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align='start' className='p-4'>
      <div className='flex flex-wrap gap-3'>
        {TAG_COLORS.map((colour) => (
          <button
            key={colour}
            className={`h-6 w-6 rounded-full transition-all hover:scale-110 ${
              selectedColor === colour
                ? 'ring-2 ring-primary ring-offset-2'
                : ''
            }`}
            style={{ backgroundColor: colour }}
            onClick={() => onColorSelect(colour)}
          />
        ))}
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
);

const tagSchema = z.object({
  name: z
    .string()
    .min(1, 'Tag name is required')
    .max(50, 'Tag name is too long'),
});

export default function TagsSettings() {
  const { tags, addTags, updateTag, deleteTag } = useInboxStore((state) => ({
    tags: state.tags,
    addTags: state.addTags,
    updateTag: state.updateTag,
    deleteTag: state.deleteTag,
  }));
  const user = useUserStore((state) => state.user);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColor, setNewTagColor] = useState(DEFAULT_TAG_COLOR);
  const [editingTag, setEditingTag] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPipelineTag, setIsPipelineTag] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(null);

  const isAdmin = user?.role === 'admin';

  const handleCreateTag = () => {
    try {
      tagSchema.parse({ name: newTagName });

      toast.promise(createCustomerTag(newTagName, newTagColor, isPipelineTag), {
        loading: 'Creating tag...',
        success: (response) => {
          addTags(response);
          setNewTagName('');
          setNewTagColor(DEFAULT_TAG_COLOR);
          setIsPipelineTag(false);
          setIsCreateDialogOpen(false);
          return 'Tag created successfully';
        },
        error: (error) => {
          return error.response?.data?.message || 'Failed to create tag';
        },
      });
    } catch (error) {
      if (error instanceof z.ZodError) {
        toast.error(error.errors[0].message);
      }
    }
  };

  const handleEditTag = (tag) => {
    try {
      tagSchema.parse({ name: tag.name });

      toast.promise(
        updatCustomerTag(
          tag._id,
          tag.name,
          tag.colour || DEFAULT_TAG_COLOR,
          tag.pipeline_tag
        ),
        {
          loading: 'Updating tag...',
          success: (response) => {
            updateTag(response);
            setEditingTag(null);
            return 'Tag updated successfully';
          },
          error: (error) => {
            return error.response?.data?.message || 'Failed to update tag';
          },
        }
      );
    } catch (error) {
      if (error instanceof z.ZodError) {
        toast.error(error.errors[0].message);
      }
    }
  };

  const handleDeleteTag = (tagId, forceDelete = false) => {
    toast.promise(deleteCustomerTag(tagId, forceDelete), {
      loading: 'Deleting tag...',
      success: () => {
        deleteTag(tagId);
        setDeleteWarning(null);
        return 'Tag deleted successfully';
      },
      error: (error) => {
        if (
          error.response?.data?.status === 'warning' &&
          error.response?.data?.contacts_count
        ) {
          setDeleteWarning({
            tagId,
            contactsCount: error.response.data.contacts_count,
          });
          return 'Tag has associated contacts';
        }
        return error.response?.data?.message || 'Failed to delete tag';
      },
    });
  };

  const otherTagConditions = [
    { _id: 'No-Tag-SBCCRM', name: 'No Tags' },
    { _id: 'Seen-Not-Responded', name: 'Seen & Not Responded' },
    { _id: 'Not-Responded', name: 'Not Responded' },
  ];

  const filteredTags = tags
    .filter(
      (tag) =>
        !otherTagConditions.some((condition) => condition._id === tag._id)
    )
    .filter((tag) =>
      tag.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <div className='space-y-6'>
      <div className='flex items-center justify-between'>
        <div>
          <h3 className='text-2xl font-bold'>Tags</h3>
          <p className='text-sm text-muted-foreground'>
            Manage tags across your team
          </p>
        </div>
        {isAdmin && (
          <CreateTagDialog
            isOpen={isCreateDialogOpen}
            onOpenChange={setIsCreateDialogOpen}
            newTagName={newTagName}
            setNewTagName={setNewTagName}
            newTagColor={newTagColor}
            setNewTagColor={setNewTagColor}
            isPipelineTag={isPipelineTag}
            setIsPipelineTag={setIsPipelineTag}
            onCreateTag={handleCreateTag}
          />
        )}
      </div>

      <div>
        <Input
          type='search'
          placeholder='Search tags...'
          className='w-full'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <p className='text-sm text-muted-foreground'>
        Tags allow you to organize conversations and threads in your team. Tags
        are synced across all team members.
      </p>

      <div className='flex flex-wrap gap-2'>
        {filteredTags.map((tag) => (
          <Badge
            key={tag._id}
            variant='secondary'
            className={`flex cursor-default items-center gap-2 px-3 py-2 text-sm ${
              tag.pipeline_tag
                ? 'border border-primary/20 bg-primary/10 hover:bg-primary/20'
                : 'bg-secondary hover:bg-secondary/80'
            }`}
          >
            <div
              className='h-2.5 w-2.5 rounded-full'
              style={{ backgroundColor: tag.colour || DEFAULT_TAG_COLOR }}
            />
            <span>{tag.name}</span>
            {tag.pipeline_tag && <AwardIcon className='h-4 w-4 text-primary' />}
            {isAdmin && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button className='ml-1 rounded-full hover:bg-muted'>
                    <DotsVerticalIcon className='h-3 w-3' />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end' className='min-w-[6rem]'>
                  <DropdownMenuItem
                    className='cursor-pointer'
                    onClick={() => setEditingTag(tag)}
                  >
                    Edit
                  </DropdownMenuItem>
                  {!tag.pipeline_tag && (
                    <DropdownMenuItem
                      className='cursor-pointer'
                      onClick={() => handleDeleteTag(tag._id)}
                    >
                      Delete
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </Badge>
        ))}
      </div>

      <EditTagDialog
        editingTag={editingTag}
        setEditingTag={setEditingTag}
        onEditTag={handleEditTag}
      />

      <DeleteTagAlert
        deleteWarning={deleteWarning}
        setDeleteWarning={setDeleteWarning}
        onDeleteTag={handleDeleteTag}
      />
    </div>
  );
}

// Separated Dialog Components
function CreateTagDialog({
  isOpen,
  onOpenChange,
  newTagName,
  setNewTagName,
  newTagColor,
  setNewTagColor,
  isPipelineTag,
  setIsPipelineTag,
  onCreateTag,
}) {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button>Add Tag</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Tag</DialogTitle>
        </DialogHeader>
        <div className='space-y-6'>
          <div className='flex items-center gap-1'>
            <ColorPicker
              selectedColor={newTagColor}
              onColorSelect={setNewTagColor}
            />
            <Input
              placeholder='Tag name'
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
            />
          </div>
          <div className='flex items-start space-x-2'>
            <Checkbox
              id='primary-tag'
              checked={isPipelineTag}
              onCheckedChange={setIsPipelineTag}
            />
            <div className='grid gap-1.5 leading-none'>
              <Label
                htmlFor='primary-tag'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                Primary Tag
              </Label>
              <p className='text-sm text-muted-foreground'>
                Primary tags appear in the conversation list.
              </p>
            </div>
          </div>
          <Button onClick={onCreateTag}>Create</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function EditTagDialog({ editingTag, setEditingTag, onEditTag }) {
  return (
    <Dialog open={!!editingTag} onOpenChange={() => setEditingTag(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Tag</DialogTitle>
        </DialogHeader>
        <div className='space-y-4'>
          <div className='flex items-center gap-2'>
            <ColorPicker
              selectedColor={editingTag?.colour || DEFAULT_TAG_COLOR}
              onColorSelect={(colour) =>
                setEditingTag({ ...editingTag, colour })
              }
            />
            <Input
              placeholder='Tag name'
              value={editingTag?.name || ''}
              onChange={(e) =>
                setEditingTag({ ...editingTag, name: e.target.value })
              }
            />
          </div>
          <div className='flex items-start space-x-2 rounded-lg border border-primary/10 bg-primary/5 p-4'>
            <Checkbox
              id='edit-primary-tag'
              checked={editingTag?.pipeline_tag || false}
              onCheckedChange={(checked) =>
                setEditingTag({ ...editingTag, pipeline_tag: checked })
              }
            />
            <div className='grid gap-1.5 leading-none'>
              <div className='flex items-center gap-2'>
                <Label
                  htmlFor='edit-primary-tag'
                  className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                >
                  Pipeline Tag
                </Label>
                <AwardIcon className='h-3 w-3 text-primary' />
              </div>
              <p className='text-sm text-muted-foreground'>
                Pipeline tags appear in the conversation list and help track
                conversation status.
              </p>
            </div>
          </div>
          <Button onClick={() => onEditTag(editingTag)}>Save</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function DeleteTagAlert({ deleteWarning, setDeleteWarning, onDeleteTag }) {
  return (
    <AlertDialog
      open={!!deleteWarning}
      onOpenChange={() => setDeleteWarning(null)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Tag Deletion</AlertDialogTitle>
          <AlertDialogDescription>
            {deleteWarning?.contactsCount &&
              `This tag is associated with ${deleteWarning.contactsCount} contact(s). Are you sure you want to proceed?`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setDeleteWarning(null)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => onDeleteTag(deleteWarning.tagId, true)}
            className='bg-destructive text-destructive-foreground hover:bg-destructive/90'
          >
            Delete Anyway
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
