import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { PhoneInput } from '@/components/common/PhoneInput';

const phoneSchema = z.object({
  phone: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
});

const MobileVerificationDialog = ({ isOpen, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(phoneSchema),
    defaultValues: {
      phone: '', // Changed from phoneNumber to phone
    },
  });

  const handlePhoneNumber = async (data) => {
    try {
      setIsLoading(true);
      await onSubmit(data.phone);
      onClose();
    } catch (err) {
      toast.error('Failed to setup mobile 2FA');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Setup Mobile Authentication</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handlePhoneNumber)}
            className='flex flex-col items-start space-y-8'
          >
            <FormField
              control={form.control}
              name='phone' // Changed from phoneNumber to phone
              render={({ field }) => (
                <FormItem className='flex flex-col items-start py-4'>
                  <FormControl className='w-full'>
                    <PhoneInput
                      placeholder='Enter a phone number'
                      // defaultCountry='US'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='flex justify-end space-x-2'>
              <Button type='button' variant='outline' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit' disabled={isLoading}>
                {isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
                Continue
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default MobileVerificationDialog;
