import { useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '../../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';
import { Input } from '../../ui/input';

const passwordSchema = z.object({
  password: z.string().min(1, 'Password is required'),
});

const PasswordVerificationDialog = ({ isOpen, onClose, onVerify }) => {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = async () => {
    try {
      passwordSchema.parse({ password });
      setIsLoading(true);
      await onVerify(password);
      setIsLoading(false);
      setPassword('');
    } catch (err) {
      if (err instanceof z.ZodError) {
        toast({
          variant: 'destructive',
          description: err.errors[0].message,
        });
      }
    }
  };

  const handleClose = () => {
    setPassword('');
    setIsLoading(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Verify Password</DialogTitle>
        </DialogHeader>
        <Input
          type='password'
          placeholder='Enter your password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <DialogFooter>
          <Button variant='outline' onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleVerify} disabled={!password || isLoading}>
            {isLoading ? 'Verifying...' : 'Verify'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordVerificationDialog;
