import {
  agentList,
  conversationList,
  createTag,
  customerTagList,
  newCustomerTagList,
  planList,
  updateOrDeleteTag,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchConversations(filter, page, status, order) {
  console.log('fetch conversation functions');
  return apiClient.create(
    conversationList,
    {
      filters: filter,
      page: page,
      status: status.value,
      order: order,
    },
    true
  );
}

export function fetchCustomerTags() {
  // return apiClient.get(customerTagList, {}, true);
  // Commenting above line and adding below line since we have changed the endpoint
  return apiClient.get(newCustomerTagList, {}, true);
}

export function createCustomerTag(tagName, color, isPipelineTag) {
  return apiClient.create(
    createTag,
    {
      name: tagName,
      colour: color,
      pipeline_tag: isPipelineTag,
    },
    true
  );
}

export function updatCustomerTag(tagId, tagName, color, isPipelineTag) {
  return apiClient.overwrite(
    updateOrDeleteTag(tagId),
    { name: tagName, colour: color, pipeline_tag: isPipelineTag },
    true
  );
}

export function deleteCustomerTag(tagId, forceDelete) {
  return apiClient.delete(
    updateOrDeleteTag(tagId),
    { force: forceDelete },
    true
  );
}

export function fetchOwnerTags() {
  return apiClient.get(agentList, {}, true);
}

export function fetchCustomerPlans() {
  return apiClient.get(planList, {}, true);
}
