import { useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { createAgent } from '@/hooks/agent';

import { Button } from '../ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

// Zod form to validate the user form
const formSchema = z
  .object({
    email: z
      .string({
        required_error: 'email cannot be empty.',
      })
      .trim()
      .email(),
    firstname: z.string({
      required_error: 'First Name is required.',
    }),
    lastname: z.string({
      required_error: 'Last Name is required.',
    }),
    password: z
      .string()
      .min(8, {
        message: 'password must be at least 8 characters.',
      })
      .max(15, {
        message: 'password can be a max of 15 characters.',
      }),
    confirmPassword: z
      .string()
      .min(8, {
        message: 'password must be at least 8 characters.',
      })
      .max(15, {
        message: 'password can be a max of 15 characters.',
      }),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The passwords did not match',
        path: ['confirmPassword'],
      });
    }
  });

const AgentCreateForm = ({ className, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const addOwner = useInboxStore((state) => state.addOwner);

  const { addAgent, createAgentModalStatus, setCreateAgentModalStatus } =
    useTeamMemberStore(
      useShallow((state) => ({
        addAgent: state.addAgent,
        createAgentModalStatus: state.createAgentModalStatus,
        setCreateAgentModalStatus: state.setCreateAgentModalStatus,
      }))
    );

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      firstname: '',
      lastname: '',
      password: '',
      confirmPassword: '',
    },
  });

  const handleClose = () => {
    setIsLoading(false);
    setCreateAgentModalStatus(false);
    form.reset();
  };

  function onSubmit(values) {
    console.log(values);
    const agentData = {
      email: values.email,
      first_name: values.firstname,
      last_name: values.lastname,
      password: values.password,
    };

    toast.promise(createAgent(agentData), {
      loading: 'Creating agent...',
      success: (response) => {
        addOwner(response.agent);
        addAgent(response.agent);
        handleClose();
        return 'Agent created successfully';
      },
      error: (error) => {
        setIsLoading(false);
        return error?.response?.data?.message || 'Failed to create agent';
      },
    });
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid gap-3'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Email'
                      type='email'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='firstname'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='First Name'
                      type='text'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='lastname'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Last Name'
                      type='text'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Password'
                      type='password'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Confirm Password'
                      type='password'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={isLoading} className='mt-2'>
              {isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
              Register
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default AgentCreateForm;
