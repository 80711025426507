import useUserStore from '../../stores/UserStore';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import ChangePasswordComponent from './security/ChangePasswordComponent';
import SessionManagement from './security/SessionManagement';
import TwoFactorAuth from './security/TwoFactorAuth';

const PasswordAndSecurity = () => {
  const tfaStatus = useUserStore((state) => state.tfaStatus);

  return (
    <div className='space-y-6 p-1'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-xl font-medium'>Password and Security</h3>
          <p className='text-sm text-muted-foreground'>
            Manage your account security settings
          </p>
        </div>
      </div>
      <Separator />
      <div className='space-y-8'>
        <ChangePasswordComponent />
        <Separator />
        {tfaStatus && <TwoFactorAuth />}
      </div>
    </div>
  );
};

export default PasswordAndSecurity;
