import {
  addContactNote,
  addContactTag,
  attachPlan,
  commentThread,
  contactDetails,
  contactMessages,
  contactNote,
  contactTagList,
  copilotChat,
  customerTagList,
  reactMessage,
  removeContactTag,
  replyToComment,
  sendMedia,
  sendMessage,
  setContactStatus,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchContactDetails(contactId) {
  return apiClient.get(contactDetails(contactId), null, true);
}

export function fetchContactTagList(contactId) {
  return apiClient.get(contactTagList(contactId), null, true);
}

// Add Contact Tag
export function addTagToContact(contactId, tag, clientId) {
  return apiClient.create(
    addContactTag,
    { tag_name: tag, contact_id: contactId },
    true,
    clientId
  );
}

// Remove Contact Tag
export function removeTagFromContact(contactId, tag, clientId) {
  return apiClient.create(
    removeContactTag,
    { tag_name: tag, contact_id: contactId },
    true,
    clientId
  );
}

export function updateContactStatus(contactId, status, clientId) {
  return apiClient.create(
    setContactStatus,
    { contact_id: contactId, status },
    true,
    clientId
  );
}

export function fetchCustomerTagsList() {
  return apiClient.get(customerTagList, null, true);
}

//Contact Notes
export function fetchContactNotes(contactId) {
  return apiClient.get(contactNote(contactId), null, true);
}

export function createContactNote(contactId, note) {
  return apiClient.create(
    addContactNote,
    { contact_id: contactId, data: note },
    true
  );
}

export function fetchContactMessages(contactId, page) {
  return apiClient.get(contactMessages(contactId), { page: page }, true);
}

export function fetchContactMessagesFB(contactId) {
  return apiClient.get(
    contactMessages(contactId),
    { load_fb_past_messages: true },
    true
  );
}

export function sendContactMessage(contactId, message) {
  return apiClient.create(
    sendMessage,
    { message, contact_id: contactId },
    true
  );
}

export function sendContactFileAttachment(contactId, formData) {
  return apiClient.create(
    sendMedia(contactId),
    formData,
    true,
    false,
    'multipart/form-data'
  );
}

export function attachPlanToContact(contactId, planId, clientId) {
  return apiClient.create(
    attachPlan,
    {
      contact_id: contactId,
      plan_id: planId,
    },
    true,
    clientId
  );
}

export function retrieveCommentThread(messageId) {
  return apiClient.get(commentThread(messageId), null, true);
}

export function sendReplyToComment(sbcParentId, commentId, message, contactId) {
  return apiClient.create(
    replyToComment,
    {
      ig_reply_to_comment_id: commentId,
      message: message,
      contact_id: contactId,
      sbc_msg_parent_id: sbcParentId,
    },
    true
  );
}

export function sendReactionToMessage(
  messageId,
  contactId,
  channel = 'instagram',
  reaction
) {
  return apiClient.create(
    reactMessage(channel),
    {
      contact_id: contactId,
      reaction: reaction,
      msg_id: messageId,
    },
    true
  );
}

export function sendPrivateReplyToComment(contactId, message, sbcMessageId) {
  return apiClient.create(
    sendMessage,
    {
      message,
      contact_id: contactId,
      cmt_msg_id: sbcMessageId,
    },
    true
  );
}

export function sendCopilotMessage(
  type,
  contactId,
  sbcMessageId = null,
  messageText = null
) {
  let data;
  if (type === 'prediction') {
    data = {
      type,
      contact_id: contactId,
      sbc_msg_id: sbcMessageId,
    };
  } else if (type === 'question') {
    data = {
      type,
      contact_id: contactId,
      message_text: messageText,
    };
  }
  return apiClient.create(copilotChat, data, true);
}

export function sendCopilotFeedback(
  copilotMessageId,
  copilotResponseId,
  isUseful,
  suggestion = 'Good'
) {
  return apiClient.create(
    copilotChat,
    {
      type: 'feedback',
      _id: copilotMessageId,
      copilot_response_id: copilotResponseId,
      feedback: {
        suggestion,
        is_useful: isUseful,
      },
    },
    true
  );
}
