import { useEffect, useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { CheckCircle2, Copy, Loader2 } from 'lucide-react';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { verify2faSetup } from '@/hooks/auth';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import RecoveryCodesDisplay from './components/RecoveryCodesDisplay';
import TFAOTPInput from './components/TFAOTPInput';

const TOTPSetup = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [secretCopied, setSecretCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(true);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [setupComplete, setSetupComplete] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  const navigate = useNavigate();
  const {
    tfaQRCodeUrl,
    tfaTOTPSecret,
    clearTFASetupData,
    setTFAState,
    validSetupSession,
    setValidSetupSession,
  } = useUserStore(
    useShallow((state) => ({
      tfaQRCodeUrl: state.tfaQRCodeUrl,
      tfaTOTPSecret: state.tfaTOTPSecret,
      clearTFASetupData: state.clearTFASetupData,
      setTFAState: state.setTFAState,
      validSetupSession: state.validSetupSession,
      setValidSetupSession: state.setValidSetupSession,
    }))
  );

  useEffect(() => {
    setIsValidating(true);
    if (!validSetupSession.totp) {
      navigate('/settings/password-and-security');
      return;
    }
    setIsValidating(false);
    return () => {
      setValidSetupSession('totp', false);
    };
  }, [validSetupSession.totp, navigate, setValidSetupSession]);

  if (isValidating) {
    return null;
  }

  const handleVerifyTOTP = async () => {
    toast.promise(verify2faSetup(verificationCode), {
      loading: 'Verifying code...',
      success: (response) => {
        if (response.recovery_codes) {
          setRecoveryCodes(response.recovery_codes);
          setSetupComplete(true);
        } else {
          clearTFASetupData();
          navigate('/settings/password-and-security');
        }
        setTFAState('totp', true);
        return 'Two-factor authentication enabled successfully';
      },
      error: (err) => {
        return err.response?.data?.message || 'Failed to verify code';
      },
    });
  };

  const handleCopySecret = async () => {
    try {
      await navigator.clipboard.writeText(tfaTOTPSecret);
      setSecretCopied(true);
      setTimeout(() => setSecretCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy secret:', err);
    }
  };

  const handleCancel = () => {
    clearTFASetupData();
    setVerificationCode('');
    setIsVerifying(false);
    setSecretCopied(false);
    setShowQRCode(true);
    setRecoveryCodes([]);
    setSetupComplete(false);
    navigate('/settings/password-and-security');
  };

  const handleDone = () => {
    clearTFASetupData();
    navigate('/settings/password-and-security');
  };

  return (
    <div className='flex min-h-[80vh] items-start justify-center'>
      <div className='w-full max-w-lg space-y-6 text-center'>
        <div>
          <h3 className='text-xl font-medium'>
            {!setupComplete ? 'Set up Two-Factor Authentication' : ''}
          </h3>
          {!setupComplete && (
            <p className='text-sm text-muted-foreground'>
              {showQRCode
                ? 'Scan the QR code with your authenticator app'
                : 'Enter this code in your authenticator app'}
            </p>
          )}
        </div>

        {!setupComplete ? (
          <div className='space-y-6'>
            <div className='mx-auto flex h-[300px] w-full flex-col items-center justify-center'>
              {showQRCode ? (
                <div className='w-full space-y-4'>
                  <div className='flex justify-center'>
                    <div className='rounded-lg border p-1'>
                      <QRCodeCanvas
                        value={tfaQRCodeUrl}
                        size={256}
                        level='H'
                        includeMargin
                        className='rounded'
                      />
                    </div>
                  </div>
                  <Button
                    variant='ghost'
                    className='text-sm text-primary hover:text-primary/90'
                    onClick={() => setShowQRCode(false)}
                  >
                    Trouble scanning?
                  </Button>
                </div>
              ) : (
                <div className='w-full space-y-4'>
                  <div className='flex items-center justify-center gap-2 rounded-lg border p-2'>
                    <code className='break-all rounded bg-muted px-4 py-2 font-mono text-base'>
                      {tfaTOTPSecret}
                    </code>
                    <Button
                      variant='ghost'
                      size='sm'
                      className='h-8 w-8 flex-shrink-0 p-0'
                      onClick={handleCopySecret}
                    >
                      {secretCopied ? (
                        <CheckCircle2 className='h-4 w-4 text-green-600' />
                      ) : (
                        <Copy className='h-4 w-4' />
                      )}
                    </Button>
                  </div>
                  <Button
                    variant='ghost'
                    className='text-sm text-primary hover:text-primary/90'
                    onClick={() => setShowQRCode(true)}
                  >
                    Scan QR code instead
                  </Button>
                </div>
              )}
            </div>

            <Separator />

            <div className='space-y-4'>
              <p className='font-medium'>Verify Setup</p>
              <p className='text-sm text-muted-foreground'>
                Enter the 6-digit code from your authenticator app
              </p>
              <div className='space-y-10'>
                <div className='flex justify-center'>
                  <TFAOTPInput
                    value={verificationCode}
                    onChange={setVerificationCode}
                    isDisabled={isVerifying || setupComplete}
                  />
                </div>
                <div className='flex justify-between gap-4'>
                  <Button
                    variant='outline'
                    onClick={handleCancel}
                    className='flex-1'
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleVerifyTOTP}
                    disabled={
                      verificationCode.length !== 6 ||
                      isVerifying ||
                      setupComplete
                    }
                    className='flex-1'
                  >
                    {isVerifying ? (
                      <Loader2 className='h-4 w-4 animate-spin' />
                    ) : setupComplete ? (
                      <CheckCircle2 className='h-4 w-4' />
                    ) : (
                      'Verify'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex min-h-[60vh] items-center justify-center'>
            <RecoveryCodesDisplay
              recoveryCodes={recoveryCodes}
              onDone={handleDone}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TOTPSetup;
