import useContactStore from '@/stores/ContactStore';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { sendCopilotMessage } from '@/hooks/contact';

import CopilotIcon from './CopilotIcon';

export default function CopilotPredictionButton({ message }) {
  const { contactId, addCopilotMessage, setCopilotOpen, setCopilotLoading } =
    useContactStore(
      useShallow((state) => ({
        contactId: state._id,
        addCopilotMessage: state.addCopilotMessage,
        setCopilotOpen: state.setCopilotOpen,
        setCopilotLoading: state.setCopilotLoading,
      }))
    );

  const handleSendCopilotMessagePrediction = async () => {
    try {
      setCopilotLoading(true);
      // Create and add user message to the chat
      const userMessage = {
        _id: Math.floor(1000000000 + Math.random() * 9000000000),
        type: 'prediction',
        message_id: message._id,
        from: 'user',
        text: message.text,
        direction: 'sent',
        created_at: new Date().toISOString(),
        updated_at: null,
      };

      // Add the message to the store
      addCopilotMessage(userMessage);

      // Open the copilot popover
      setCopilotOpen(true);

      // Send the message to the copilot API
      const response = await sendCopilotMessage(
        'prediction',
        contactId,
        message._id,
        null
      );

      if (response) {
        response.data.forEach((msg) => {
          addCopilotMessage(msg);
        });
      }
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
    } finally {
      setCopilotLoading(false);
    }
  };

  return (
    <div
      className={cn(
        'flex h-6 w-6 cursor-pointer items-center justify-center self-center rounded-full bg-primary/100 text-primary opacity-0 group-hover/copilot:opacity-100'
      )}
      onClick={handleSendCopilotMessagePrediction}
      title='Ask Copilot about this message'
    >
      <CopilotIcon className='h-4 w-4' />
    </div>
  );
}
