import { useEffect, useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { verify2faSetup } from '@/hooks/auth';
import { Button } from '@/components/ui/button';

import RecoveryCodesDisplay from './components/RecoveryCodesDisplay';
import TFAOTPInput from './components/TFAOTPInput';

const MobileSetup = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);
  const [isValidating, setIsValidating] = useState(true);

  const navigate = useNavigate();
  const { tfaReference, setTFAState, validSetupSession, setValidSetupSession } =
    useUserStore(
      useShallow((state) => ({
        tfaReference: state.tfaReference,
        setTFAState: state.setTFAState,
        validSetupSession: state.validSetupSession,
        setValidSetupSession: state.setValidSetupSession,
      }))
    );

  useEffect(() => {
    setIsValidating(true);
    if (!validSetupSession.mobile) {
      navigate('/settings/password-and-security');
      return;
    }
    setIsValidating(false);
    return () => {
      setValidSetupSession('mobile', false);
    };
  }, [validSetupSession.mobile, navigate, setValidSetupSession]);

  if (isValidating) {
    return null;
  }

  const handleVerifyOTP = async () => {
    setIsVerifying(true);
    toast.promise(verify2faSetup(verificationCode), {
      loading: 'Verifying code...',
      success: (response) => {
        if (response.recovery_codes) {
          setRecoveryCodes(response.recovery_codes);
          setShowRecoveryCodes(true);
        } else {
          navigate('/settings/password-and-security');
        }
        setTFAState('mobile', true);
        return 'Two-factor authentication enabled successfully';
      },
      error: (err) => {
        setIsVerifying(false);
        return err.response?.data?.message || 'Failed to verify code';
      },
    });
  };

  const handleDone = () => {
    navigate('/settings/password-and-security');
  };

  const handleCancel = () => {
    setVerificationCode('');
    setIsVerifying(false);
    setRecoveryCodes([]);
    setShowRecoveryCodes(false);
    navigate('/settings/password-and-security');
  };

  return (
    <div className='flex min-h-[80vh] items-start justify-center'>
      <div className='w-full max-w-lg space-y-6 text-center'>
        {!showRecoveryCodes ? (
          <div className='flex min-h-[60vh] items-center justify-center'>
            <div className='space-y-10'>
              <div className='space-y-4'>
                <h3 className='text-xl font-medium'>Verify SMS Code</h3>
                <p className='text-sm text-muted-foreground'>
                  Enter the verification code sent to {tfaReference}
                </p>
                <div className='flex justify-center'>
                  <TFAOTPInput
                    value={verificationCode}
                    onChange={setVerificationCode}
                    isDisabled={isVerifying}
                  />
                </div>
              </div>

              <div className='flex justify-between gap-4'>
                <Button
                  variant='outline'
                  onClick={handleCancel}
                  className='flex-1'
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleVerifyOTP}
                  disabled={verificationCode.length !== 6 || isVerifying}
                  className='flex-1'
                >
                  {isVerifying ? (
                    <Loader2 className='h-4 w-4 animate-spin' />
                  ) : (
                    'Verify'
                  )}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex min-h-[60vh] items-center justify-center'>
            <RecoveryCodesDisplay
              recoveryCodes={recoveryCodes}
              onDone={handleDone}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSetup;
