import { useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import { ClipboardPenIcon } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useShallow } from 'zustand/react/shallow';

import { Avatar } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';

import CopilotFeedbackButtons from './CopilotFeedbackButtons';
import CopilotIcon from './CopilotIcon';
import LoadingMessage from './LoadingMessage';
import MarkdownComponents from './MarkdownComponents';

const CopilotMessageList = ({ isLoading, setIsOpen, addMessage }) => {
  // Get store values
  const { copilotMessages, profile_picture_url, fullname, ig_username } =
    useContactStore(
      useShallow((state) => ({
        copilotMessages: state.copilotMessages,
        profile_picture_url: state.profile_picture_url,
        fullname: state.fullname,
        ig_username: state.ig_username,
      }))
    );

  const shouldShowFeedbackButtons = (message, index) => {
    if (message.from === 'user') return false;

    // Find the index of the last user message in the entire conversation
    const lastUserMessageIndex = copilotMessages
      .map((msg, idx) => ({ msg, idx }))
      .filter((item) => item.msg.from === 'user')
      .pop()?.idx;

    // Only show feedback buttons for copilot messages that came after the last user message
    return lastUserMessageIndex !== undefined && index > lastUserMessageIndex;
  };

  if (copilotMessages.length === 0) {
    return (
      <div className='flex items-center gap-2 rounded-lg bg-secondary/50 p-3'>
        <span className='text-sm text-muted-foreground'>
          Got any questions from your chat with {fullname || ig_username}?
        </span>
        <Avatar className='h-6 w-6'>
          <img
            src={profile_picture_url || '/default-avatar.png'}
            alt={fullname?.split(' ')[0] || ig_username || 'Contact'}
          />
        </Avatar>
      </div>
    );
  }

  return (
    <div className='space-y-2'>
      {copilotMessages.map((message, index) => (
        <MessageItem
          key={index}
          message={message}
          index={index}
          shouldShowFeedbackButtons={shouldShowFeedbackButtons}
          setIsOpen={setIsOpen}
          addMessage={addMessage}
        />
      ))}
      {isLoading && <LoadingMessage />}
    </div>
  );
};

export default CopilotMessageList;

// MessageItem component moved inside MessageList.jsx
const MessageItem = ({
  message,
  index,
  shouldShowFeedbackButtons,
  setIsOpen,
  addMessage,
}) => {
  const remarkPlugins = [remarkGfm];
  const [isHovering, setIsHovering] = useState(false);
  const { profile_picture_url, fullname, ig_username, setMessageToTweak } =
    useContactStore(
      useShallow((state) => ({
        profile_picture_url: state.profile_picture_url,
        fullname: state.fullname,
        ig_username: state.ig_username,
        setMessageToTweak: state.setMessageToTweak,
      }))
    );

  return (
    <div
      key={index}
      className='space-y-1'
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {message.from === 'user' ? (
        <div className='flex items-end gap-2'>
          <Avatar className='h-8 w-8 shrink-0'>
            <img
              src={profile_picture_url || '/default-avatar.png'}
              alt={fullname || ig_username}
            />
          </Avatar>
          <div className='max-w-[100%] rounded-lg bg-secondary/50 p-2'>
            <p className='whitespace-pre-wrap break-words text-sm text-foreground'>
              {message.text}
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className='flex max-w-full items-start justify-start gap-2'>
            <div className='flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-primary'>
              <CopilotIcon className='h-5 w-5' />
            </div>
            <div className='max-w-[calc(100%-4rem)] rounded-lg bg-received p-2'>
              <div className='text-sm text-primary-foreground'>
                <ReactMarkdown
                  remarkPlugins={remarkPlugins}
                  components={MarkdownComponents}
                >
                  {message.text}
                </ReactMarkdown>
              </div>
            </div>
            {message.type === 'prediction' && (
              <Button
                size='xs'
                variant='ghost'
                className='h-8 w-8 shrink-0 self-start rounded-lg p-0 hover:bg-secondary/50'
                onClick={() => {
                  setMessageToTweak(message.text);
                  setIsOpen(false);
                }}
                title="I'll Tweak and Send"
              >
                <ClipboardPenIcon className='h-4 w-4' />
              </Button>
            )}
            {/* TODO: Remove temporarily disabl feedback buttons */}
            {shouldShowFeedbackButtons(message, index) &&
              false &&
              isHovering && (
                <CopilotFeedbackButtons
                  message={message}
                  addMessage={addMessage}
                />
              )}
          </div>
        </>
      )}
    </div>
  );
};
