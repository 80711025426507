import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Check, CheckCircle2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';

import { changePassword } from '@/hooks/auth';
import { ButtonLoading } from '@/components/common/button/loading-button';

import { Button } from '../../ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';

const passwordChangeSchema = z
  .object({
    currentPassword: z.string().min(1, 'Current password is required'),
    newPassword: z.string().min(8, 'Password must be at least 8 characters'),
    confirmPassword: z.string().min(1, 'Please confirm your password'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })
  .refine((data) => data.currentPassword !== data.newPassword, {
    message: 'New password must be different from current password',
    path: ['newPassword'],
  });

const ChangePasswordComponent = () => {
  const [showForm, setShowForm] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(passwordChangeSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    toast.promise(changePassword(data.currentPassword, data.newPassword), {
      loading: 'Changing password...',
      success: () => {
        setIsLoading(false);
        setIsSuccess(true);
        setShowForm(false);
        form.reset();
        return 'Password successfully changed';
      },
      error: (error) => {
        setIsLoading(false);
        return error.response?.data?.message || 'Failed to change password';
      },
    });
  };

  if (isSuccess) {
    return (
      <div className='space-y-4'>
        <h4 className='text-lg font-medium'>Change Password</h4>
        <div className='flex items-center gap-2 text-green-600'>
          <Check className='h-5 w-5' />
          <span>Password successfully changed</span>
        </div>
      </div>
    );
  }

  return (
    <div className='space-y-4'>
      <div className='flex max-w-2xl flex-col items-start space-y-4'>
        <div className='space-y-1'>
          <h4 className='text-lg font-medium'>Change Password</h4>
          <p className='text-sm text-muted-foreground'>
            It&apos;s a good idea to use a strong password that you don&apos;t
            use elsewhere
          </p>
        </div>
        {!showForm && (
          <Button
            variant='outline'
            onClick={() => setShowForm(true)}
            className='gap-2'
          >
            Change Password
          </Button>
        )}
      </div>

      {showForm && (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className='max-w-md space-y-4'
          >
            <FormField
              control={form.control}
              name='currentPassword'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Current Password</FormLabel>
                  <FormControl>
                    <Input type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='newPassword'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm New Password</FormLabel>
                  <FormControl>
                    <Input type='password' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className='space-x-2'>
              <Button
                type='button'
                variant='outline'
                onClick={() => {
                  setShowForm(false);
                  form.reset();
                }}
              >
                Cancel
              </Button>
              {isLoading ? (
                <ButtonLoading />
              ) : (
                <Button type='submit'>Update Password</Button>
              )}
            </div>
          </form>
        </Form>
      )}
    </div>
  );
};

export default ChangePasswordComponent;
