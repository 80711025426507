import { useEffect, useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { switch2faMethodOrSendOTP, verify2faSetup } from '@/hooks/auth';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const COUNTDOWN_TIME = 60;
const RECOVERY_CODE_REGEX = /^[a-zA-Z0-9]*$/;

const TwoFactorVerificationDialog = ({
  isOpen,
  onClose,
  onVerificationSuccess,
}) => {
  const { tfaState, tfaDefaultMethod, tfaReference, setTFAReference } =
    useUserStore(
      useShallow((state) => ({
        tfaState: state.tfaState,
        tfaDefaultMethod: state.tfaDefaultMethod,
        tfaReference: state.tfaReference,
        setTFAReference: state.setTFAReference,
      }))
    );

  // Simplified enabledMethods computation
  const enabledMethods = Object.keys(tfaState).filter(
    (method) => tfaState[method]
  );

  const [verificationCode, setVerificationCode] = useState('');
  const [selectedMethod, setSelectedMethod] = useState(tfaDefaultMethod);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [useRecoveryCode, setUseRecoveryCode] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');

  // Update selected method when tfaDefaultMethod changes
  useEffect(() => {
    if (tfaDefaultMethod) {
      setSelectedMethod(tfaDefaultMethod);
    }
  }, [tfaDefaultMethod]);

  useEffect(() => {
    setVerificationCode('');
  }, [selectedMethod]);

  // Add this effect for countdown
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  // Add this effect to start countdown when mobile is selected
  useEffect(() => {
    if (selectedMethod === 'mobile') {
      setCountdown(COUNTDOWN_TIME);
    }
  }, [selectedMethod]);

  // Reset states when recovery code toggle changes
  useEffect(() => {
    setVerificationCode('');
    setRecoveryCode('');
  }, [useRecoveryCode]);

  const handleMethodChange = async (method) => {
    try {
      const response = await switch2faMethodOrSendOTP(method);
      setSelectedMethod(method);
      if (response?.tfa_reference) {
        setTFAReference(response.tfa_reference);
      }
    } catch (error) {
      toast.error('Failed to switch 2FA method');
    }
  };

  const handleVerify = async () => {
    setIsVerifying(true);

    const codeToVerify = useRecoveryCode ? recoveryCode : verificationCode;

    await toast.promise(verify2faSetup(codeToVerify, useRecoveryCode), {
      loading: 'Verifying code...',
      success: (response) => {
        onVerificationSuccess(response);
        if (response.remaining_recovery_codes) {
          return `Verification successful! You have ${response.remaining_recovery_codes} recovery codes remaining.`;
        }
        return 'Verification successful!';
      },
      error: (error) => {
        return error.response?.data?.message || 'Verification failed';
      },
    });

    setIsVerifying(false);
  };

  const handleResendOTP = async () => {
    setIsResending(true);

    toast.promise(switch2faMethodOrSendOTP('mobile'), {
      loading: 'Sending verification code...',
      success: (response) => {
        setIsResending(false);
        setCountdown(COUNTDOWN_TIME); // Reset countdown after successful resend
        return response.message || 'Verification code sent successfully';
      },
      error: (error) => {
        setIsResending(false);
        return (
          error.response?.data?.message || 'Failed to send verification code'
        );
      },
    });
  };

  const handleRecoveryCodeChange = (e) => {
    const value = e.target.value;
    if (
      value.length <= 10 &&
      (value === '' || RECOVERY_CODE_REGEX.test(value))
    ) {
      setRecoveryCode(value);
    }
  };

  const getMethodTitle = (method) => {
    switch (method) {
      case 'totp':
        return 'Authenticator App Verification';
      case 'mobile':
        return 'SMS Verification';
      default:
        return 'Two-Factor Authentication';
    }
  };

  const getMethodDescription = (method) => {
    switch (method) {
      case 'totp':
        return 'Enter the 6-digit code from your authenticator app';
      case 'mobile':
        return (
          <>
            Enter the 6-digit code
            {tfaReference ? (
              <>
                {' '}
                sent to{' '}
                <span className='font-mono font-medium text-primary'>
                  {tfaReference}
                </span>
              </>
            ) : null}
          </>
        );
      default:
        return 'Enter verification code';
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className='p-6 sm:max-w-md'>
        <DialogHeader className='mb-4'>
          <DialogTitle className='text-xl'>
            {useRecoveryCode
              ? 'Recovery Code Verification'
              : getMethodTitle(selectedMethod)}
          </DialogTitle>
        </DialogHeader>
        <div className='space-y-6'>
          {!useRecoveryCode && enabledMethods.length > 1 && (
            <div className='space-y-2'>
              <label className='text-sm font-medium'>Verification Method</label>
              <Select value={selectedMethod} onValueChange={handleMethodChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {enabledMethods.map((method) => (
                    <SelectItem key={method} value={method}>
                      {method === 'totp' ? 'Authenticator App' : 'SMS'}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          <div className='space-y-4'>
            {useRecoveryCode ? (
              <div className='space-y-2'>
                <p className='text-sm text-muted-foreground'>
                  Enter your recovery code
                </p>
                <Input
                  type='text'
                  value={recoveryCode}
                  onChange={handleRecoveryCodeChange}
                  placeholder='Enter recovery code'
                  disabled={isVerifying}
                  maxLength={10}
                  className='font-mono'
                />
              </div>
            ) : (
              <>
                <p className='text-sm text-muted-foreground'>
                  {getMethodDescription(selectedMethod)}
                </p>
                <div className='flex justify-center py-4'>
                  <InputOTP
                    value={verificationCode}
                    onChange={setVerificationCode}
                    maxLength={6}
                    pattern={REGEXP_ONLY_DIGITS}
                    disabled={isVerifying}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </div>
              </>
            )}
          </div>

          {!useRecoveryCode && selectedMethod === 'mobile' && (
            <div className='flex flex-col items-center gap-2'>
              <Button
                variant='link'
                size='sm'
                onClick={handleResendOTP}
                disabled={isResending || countdown > 0}
                className='text-sm text-muted-foreground'
              >
                {isResending ? (
                  <>
                    <Loader2 className='mr-2 h-3 w-3 animate-spin' />
                    Sending...
                  </>
                ) : countdown > 0 ? (
                  `Resend available in ${countdown}s`
                ) : (
                  "Didn't receive code? Send again"
                )}
              </Button>
            </div>
          )}

          <div className='flex items-center space-x-2 py-2'>
            <Checkbox
              id='useRecoveryCode'
              checked={useRecoveryCode}
              onCheckedChange={setUseRecoveryCode}
            />
            <label
              htmlFor='useRecoveryCode'
              className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
            >
              Use recovery code instead
            </label>
          </div>

          <Button
            className='w-full'
            onClick={handleVerify}
            disabled={
              (useRecoveryCode
                ? !recoveryCode
                : verificationCode.length !== 6) || isVerifying
            }
          >
            {isVerifying && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
            Verify
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorVerificationDialog;
