import { useState } from 'react';
import { CheckCircle2, Copy } from 'lucide-react';

import { Button } from '@/components/ui/button';

const RecoveryCodesDisplay = ({ recoveryCodes, onDone }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(recoveryCodes.join('\n'));
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className='space-y-6'>
      <h3 className='text-xl font-medium'>Save Your Recovery Codes</h3>
      <div className='space-y-2'>
        <div className='flex items-center justify-center gap-2'>
          <p className='font-medium'>Recovery Codes</p>
          <Button
            variant='ghost'
            size='sm'
            className='h-8 w-8 p-0'
            onClick={handleCopy}
          >
            {copied ? (
              <CheckCircle2 className='h-4 w-4 text-green-600' />
            ) : (
              <Copy className='h-4 w-4' />
            )}
          </Button>
        </div>
        <p className='text-sm text-muted-foreground'>
          Save these recovery codes in a secure place. You can use these to
          login if you lose access.
        </p>
        <div className='mx-auto max-w-sm'>
          <div className='grid grid-cols-2 gap-2'>
            {recoveryCodes.map((code, index) => (
              <code
                key={index}
                className='rounded bg-muted px-2 py-1 font-mono'
              >
                {code}
              </code>
            ))}
          </div>
        </div>
      </div>
      <Button onClick={onDone} className='mt-4'>
        Done
      </Button>
    </div>
  );
};

export default RecoveryCodesDisplay;
