import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { toast } from 'sonner';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { createCannedMessage } from '@/hooks/user';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { ButtonLoading } from '@/components/common/button/loading-button';
import MentionsEditor from '@/components/settings/canned-messages/editor/MentionsEditor';

const shortcutSchema = z.object({
  symbol: z
    .string()
    .min(2, 'Shortcut must be at least 2 characters')
    .startsWith('/', 'Shortcut must start with /'),
  message: z
    .object({
      type: z.literal('doc'),
      content: z.array(z.any()).min(1, 'Message cannot be empty'),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'Message cannot be empty',
    }),
});

const CreateShortcutDialog = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState('');
  const [message, setMessage] = useState(null); // Changed to null initial state
  const [errors, setErrors] = useState({ symbol: '', message: '' });

  const { addCannedResponse } = useUserStore(
    useShallow((state) => ({
      addCannedResponse: state.addCannedResponse,
    }))
  );

  const handleSymbolChange = (e) => {
    setSymbol(e.target.value);
    setErrors((prev) => ({ ...prev, symbol: '' }));
  };

  const handleMessageChange = (value) => {
    setMessage(value);
    setErrors((prev) => ({ ...prev, message: '' }));
  };

  const handleSubmit = () => {
    try {
      shortcutSchema.parse({ symbol, message });
      if (!message?.type || !message?.content?.length) {
        setErrors((prev) => ({ ...prev, message: 'Message cannot be empty' }));
        return;
      }

      setIsLoading(true);
      const data = { shortcut: symbol, message };
      toast.promise(createCannedMessage(data), {
        loading: 'Creating saved replies...',
        success: (response) => {
          addCannedResponse(response);
          handleClose();
          return 'Saved Replies created successfully';
        },
        error: (error) => {
          setIsLoading(false);
          return (
            error.response?.data?.message || 'Failed to ceate saved replies'
          );
        },
      });
    } catch (err) {
      const newErrors = { symbol: '', message: '' };
      err.errors.forEach((error) => {
        const path = error.path[0];
        newErrors[path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  const handleClose = () => {
    setIsLoading(false);
    setSymbol('');
    setMessage(null); // Reset to null instead of empty object
    setErrors({ symbol: '', message: '' });
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Create Shortcut</DialogTitle>
          <DialogDescription>
            Please provide the shortcut and the appropriate message
          </DialogDescription>
        </DialogHeader>

        <div className='space-y-6'>
          <div className='space-y-2'>
            <label className='text-sm font-medium'>Shortcut</label>
            <Input
              value={symbol}
              onChange={handleSymbolChange}
              placeholder='Enter shortcut (e.g., /hello)'
            />
            {errors.symbol && (
              <p className='text-sm text-destructive'>{errors.symbol}</p>
            )}
          </div>

          <div className='space-y-2'>
            <label className='text-sm font-medium'>Message</label>
            <MentionsEditor value={message} onChange={handleMessageChange} />
            {errors.message && (
              <p className='text-sm text-destructive'>{errors.message}</p>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          {isLoading ? (
            <ButtonLoading />
          ) : (
            <Button onClick={handleSubmit}>Save</Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateShortcutDialog;
