import { REGEXP_ONLY_DIGITS } from 'input-otp';

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';

const TFAOTPInput = ({ value, onChange, isDisabled }) => (
  <InputOTP
    value={value}
    onChange={onChange}
    maxLength={6}
    disabled={isDisabled}
    pattern={REGEXP_ONLY_DIGITS}
    containerClassName='group flex items-center has-[:disabled]:opacity-30'
  >
    <InputOTPGroup>
      {[...Array(6)].map((_, i) => (
        <InputOTPSlot key={i} index={i} />
      ))}
    </InputOTPGroup>
  </InputOTP>
);

export default TFAOTPInput;
