import { Outlet } from 'react-router-dom';

const SecurityLayout = () => {
  return (
    <div className='container max-w-3xl py-8'>
      <Outlet />
    </div>
  );
};

export default SecurityLayout;
