import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { trackLogin, trackLoginFailed } from '@/lib/analytics-event';
import { cn, setToken } from '@/lib/utils';
import { loginUser } from '@/hooks/auth';
import TwoFactorVerificationDialog from '@/components/auth/TwoFactorVerificationDialog';

import { Alert, AlertDescription } from '../ui/alert';
import { Button } from '../ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';

// Zod form to validate the user form
const formSchema = z.object({
  email: z
    .string({
      required_error: 'email cannot be empty.',
    })
    .trim()
    .email(),
  password: z
    .string({
      required_error: 'password cannot be empty',
    })
    .min(1, { message: 'password cannot be empty' }),
});

export function UserLoginForm({ className, ...props }) {
  const {
    setUser,
    setCustomer,
    setTFAState,
    setTFADefaultMethod,
    setTFAReference,
    setTFAStatus,
    setCopilotEnabled,
    setIgapiEnabled,
  } = useUserStore(
    useShallow((state) => ({
      setUser: state.setUser,
      setCustomer: state.setCustomer,
      setTFAState: state.setTFAState,
      setTFADefaultMethod: state.setTFADefaultMethod,
      setTFAReference: state.setTFAReference,
      setTFAStatus: state.setTFAStatus,
      setCopilotEnabled: state.setCopilotEnabled,
      setIgapiEnabled: state.setIgapiEnabled,
    }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const [tfaData, setTfaData] = useState(null);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleLoginSuccess = (response) => {
    setToken(response.access_token);
    setUser(response.user);
    setCustomer(response.user.customer_id);
    setCopilotEnabled(response.user.customer.is_copilot_enabled);
    setIgapiEnabled(response.user.customer.is_igapi_enabled);
    if (response.user?.customer?.tfa_status) {
      setTFAStatus(response.user.customer.tfa_status);
    }
    trackLogin(response.user);
    setShow2FA(false);
  };

  const handle2FAVerificationSuccess = (response) => {
    handleLoginSuccess(response);
  };

  async function onSubmit(values) {
    setIsLoading(true);
    setFormMessage('');
    const { email, password } = values;

    await toast.promise(loginUser(email, password), {
      loading: 'Logging in...',
      success: (response) => {
        handleLoginSuccess(response);
        return 'Logged in successfully!';
      },
      error: (error) => {
        if (error.response?.data?.tfa_verification_required) {
          const tfaMethods = error.response?.data?.enabled_methods || [];
          if (error.response?.data?.tfa_reference) {
            setTFAReference(error.response?.data?.tfa_reference);
          }
          setTFAState('totp', tfaMethods.includes('totp'));
          setTFAState('mobile', tfaMethods.includes('mobile'));
          setTFADefaultMethod(error.response?.data?.default_method);
          setShow2FA(true);
          return '2FA verification required';
        }
        trackLoginFailed(error);
        return error.response?.data?.message || 'Login failed';
      },
    });

    setIsLoading(false);
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid gap-3'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Email'
                      type='email'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder='Password'
                      type='password'
                      autoCorrect='off'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={isLoading} className='mt-2'>
              {isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
              Login
            </Button>
          </div>
          {formMessage && (
            <div className='my-3'>
              <Alert
                variant='destructive'
                className='cursor-default select-none py-2'
              >
                <AlertDescription className='flex items-center justify-center'>
                  {formMessage}
                </AlertDescription>
              </Alert>
            </div>
          )}
        </form>
      </Form>

      <TwoFactorVerificationDialog
        isOpen={show2FA}
        onClose={() => setShow2FA(false)}
        onVerificationSuccess={handle2FAVerificationSuccess}
      />
    </div>
  );
}
