import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  _id: null,
  ig_username: null,
  channel: null,
  fullname: '',
  status: null,
  profile_picture_url: null,
  load_fb_past_messages: null,
  tags: [],
  owner: null,
  note: null,
  plan: null,
  ai_notes: null,
  followers_count: null,
  following_count: null,
  verified: false,
  bio: '',

  page: 0,
  messages: [],

  commentThread: [],
  parentCommentId: null,
  commentThreadAccordianState: '',
  commentMessage: '',

  selectedPrivateReplyMessage: '',
  selectedPrivateReplyMessageId: null,

  latest_message_received_timestamp: null,
  latest_comment_received_timestamp: null,

  rocket_selling_index: null,

  copilotMessages: [],
  messageToTweak: '',
  isCopilotOpen: false,
  isCopilotLoading: false,
};

const useContactStore = create(
  devtools(
    (set) => ({
      ...initialState,

      setContactDetails: (contactData) =>
        set({
          _id: contactData._id,
          ig_username: contactData.ig_username,
          fullname: contactData.full_name,
          status: contactData.status,
          profile_picture_url: contactData.profile_picture_url,
          load_fb_past_messages: contactData.load_fb_past_messages,
          tags: contactData.tags,
          owner: contactData.user_id,
          note: contactData.note,
          ai_notes: contactData.ai_notes,
          plan: contactData.plan,
          channel: contactData.channel,
          followers_count: contactData.followers_count,
          following_count: contactData.following_count,
          verified: contactData.verified,
          bio: contactData.bio,
          external_url: contactData.external_url,
          rocket_selling_index: contactData.rocket_selling_index,
          copilotMessages: contactData.copilot_conversations
            ? contactData.copilot_conversations
            : [],
          ...(contactData.latest_message_received_timestamp && {
            latest_message_received_timestamp:
              contactData.latest_message_received_timestamp,
          }),
          ...(contactData.latest_comment_received_timestamp && {
            latest_comment_received_timestamp:
              contactData.latest_comment_received_timestamp,
          }),
        }),
      changeContact: (contactId) => set({ _id: contactId }),
      setContactStatus: (status) =>
        set({
          status,
        }),
      setContactNote: (note) => set({ note }),
      setContactOwner: (ownerId) => set({ owner: ownerId }),
      setPlan: (plan) => set({ plan }),
      addContactTag: (newTag) =>
        set((state) => ({ tags: [...state.tags, newTag] })),
      removeContactTag: (tagId) =>
        set((state) => ({
          tags: state.tags.filter((tag) => tag._id !== tagId),
        })),
      addOrRemoveContactTag: (newTag) =>
        set(({ tags }) => {
          if (tags.some((tag) => tag._id === newTag._id)) {
            return {
              tags: tags.filter((tag) => tag._id !== newTag._id),
            };
          } else {
            return {
              tags: [...tags, newTag],
            };
          }
        }),
      setLoadFbPastMessages: (value) => set({ load_fb_past_messages: value }),

      setPage: (page) => set({ page }),
      setMessages: (messages) => set({ messages }),
      setNewMessage: (newMessage) =>
        set((state) => ({ messages: [...state.messages, newMessage] })),
      setPrevPageMessages: (prevMessages) =>
        set((state) => ({ messages: [...prevMessages, ...state.messages] })),
      setMessageReaction: (messageId, emoji) =>
        set(
          (state) => ({
            messages: state.messages.map((message) =>
              message._id === messageId ? { ...message, emoji } : message
            ),
          }),
          false,
          'set_message_reaction'
        ),
      setMessageCustomerReaction: (messageId, customerEmoji) =>
        set(
          (state) => ({
            messages: state.messages.map((message) =>
              message._id === messageId
                ? {
                    ...message,
                    customer_reaction:
                      customerEmoji === 'love' ? '❤' : customerEmoji,
                  }
                : message
            ),
          }),
          false,
          'set_message_customer_reaction'
        ),
      setMessageReadReceipt: (messageId) =>
        set(
          (state) => ({
            messages: state.messages.map((message) =>
              message._id === messageId
                ? { ...message, is_seen: true }
                : message
            ),
          }),
          false,
          'set_message_readreceipt'
        ),

      resetContactStore: () => set({ ...initialState }),

      setParentCommentId: (parentCommentId) => set({ parentCommentId }),
      setCommentThread: (commentThread) => set({ commentThread }),
      setCommentThreadAccordianState: (accordianState) =>
        set({
          commentThreadAccordianState: accordianState,
        }),
      setCommentMessage: (message) => set({ commentMessage: message }),
      resetCommentThread: () =>
        set({
          commentThread: [],
          parentCommentId: null,
          commentThreadAccordianState: '',
          commentMessage: '',
        }),
      setSelectedPrivateReplyMessage: (messageId, messageText) =>
        set({
          selectedPrivateReplyMessage: messageText,
          selectedPrivateReplyMessageId: messageId,
        }),
      resetSelectedPrivateReplyMessage: () =>
        set({
          selectedPrivateReplyMessage: '',
          selectedPrivateReplyMessageId: null,
        }),
      updateCommentResponded: (messageId) =>
        set((state) => ({
          messages: state.messages.map((message) =>
            message._id === messageId
              ? { ...message, is_privately_responded: true }
              : message
          ),
        })),
      addCopilotMessage: (message) =>
        set((state) => ({
          copilotMessages: [...state.copilotMessages, message],
        })),
      resetCopilotMessages: () => set({ copilotMessages: [] }),
      updateCopilotMessageFeedback: (messageId, responseId, isUseful) =>
        set((state) => ({
          copilotMessages: state.copilotMessages.map((message) =>
            message._id === messageId && message.response_id === responseId
              ? {
                  ...message,
                  feedback: {
                    is_useful: isUseful,
                  },
                }
              : message
          ),
        })),
      setMessageToTweak: (message) => set({ messageToTweak: message }),
      resetMessageToTweak: () => set({ messageToTweak: '' }),
      setCopilotOpen: (isOpen) => set({ isCopilotOpen: isOpen }),
      setCopilotLoading: (isLoading) => set({ isCopilotLoading: isLoading }),
    }),

    { name: 'ContactStore' }
  )
);

export default useContactStore;
