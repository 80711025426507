// Zustand store for User and Auth Details
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {
  user: null,
  authDetails: null,
  customerId: null,
  clientId: null,
  ablyConnectionStatus: null,
  cannedResponses: [],
  tfaReference: null,
  tfaQRCodeUrl: null,
  tfaTOTPSecret: null,
  tfaState: {
    totp: false,
    mobile: false,
  },
  tfaStatus: null,
  tfaDefaultMethod: null,
  validSetupSession: {
    totp: false,
    mobile: false,
  },
  copilotEnabled: null,
  igapiEnabled: null,
};

// Sample user object
const useUserStore = create(
  devtools(
    (set) => ({
      ...initialState,
      setUser: (user) => set({ user }, false, 'set_user'),
      setAuthDetails: (authDetails) => set({ authDetails }),

      setCustomer: (customerId) => set({ customerId }),
      setTFAStatus: (tfaStatus) => set({ tfaStatus }),
      setCopilotEnabled: (copilotEnabled) => set({ copilotEnabled }),
      setIgapiEnabled: (igapiEnabled) => set({ igapiEnabled }),
      setClientId: (clientId) => set({ clientId }),

      setAblyConnectionStatus: (status) =>
        set({ ablyConnectionStatus: status }),

      resetUserStore: () => set({ ...initialState }),

      setCannedResponses: (cannedResponses) => set({ cannedResponses }),
      addCannedResponse: (newCannedResponse) => {
        set((state) => ({
          cannedResponses: [...state.cannedResponses, newCannedResponse],
        }));
      },
      updateCannedResponse: (updatedCannedResponse) => {
        set((state) => ({
          cannedResponses: state.cannedResponses.map((cannedResponse) =>
            cannedResponse._id === updatedCannedResponse._id
              ? updatedCannedResponse
              : cannedResponse
          ),
        }));
      },
      removeCannedResponse: (cannedResponseId) => {
        set((state) => ({
          cannedResponses: state.cannedResponses.filter(
            (cannedResponse) => cannedResponse._id !== cannedResponseId
          ),
        }));
      },
      setTFASetupData: (qrCodeUrl, totpSecret) =>
        set({ tfaQRCodeUrl: qrCodeUrl, tfaTOTPSecret: totpSecret }),
      clearTFASetupData: () =>
        set({
          tfaQRCodeUrl: null,
          tfaTOTPSecret: null,
        }),
      setTFAState: (method, value) =>
        set((state) => ({
          tfaState: {
            ...state.tfaState,
            [method]: value,
          },
        })),
      setTFADefaultMethod: (method) => set({ tfaDefaultMethod: method }),
      setTFAReference: (reference) => set({ tfaReference: reference }),
      setValidSetupSession: (method, value) =>
        set((state) => ({
          validSetupSession: {
            ...state.validSetupSession,
            [method]: value,
          },
        })),
      clearValidSetupSessions: () =>
        set(() => ({
          validSetupSession: {
            totp: false,
            mobile: false,
          },
        })),
    }),
    { name: 'userStore' }
  )
);

export default useUserStore;
