import { useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { sendCopilotMessage } from '@/hooks/contact';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';

const CopilotInput = ({ scrollToBottom }) => {
  const [inputText, setInputText] = useState('');

  // Get store values
  const { _id, addCopilotMessage, isCopilotLoading, setCopilotLoading } =
    useContactStore(
      useShallow((state) => ({
        _id: state._id,
        addCopilotMessage: state.addCopilotMessage,
        isCopilotLoading: state.isCopilotLoading,
        setCopilotLoading: state.setCopilotLoading,
      }))
    );

  const handleSendCopilotMessage = async () => {
    if (!inputText.trim()) return;

    try {
      setCopilotLoading(true);

      // Add user message to the chat
      const userMessage = {
        _id: Math.floor(1000000000 + Math.random() * 9000000000),
        type: 'question',
        message_id: null,
        from: 'user',
        text: inputText,
        direction: 'sent',
        created_at: new Date().toISOString(),
        updated_at: null,
      };
      addCopilotMessage(userMessage);

      // Force scroll to bottom after adding user message
      setTimeout(scrollToBottom, 50);

      // Send message to copilot
      const response = await sendCopilotMessage(
        'question',
        _id,
        null,
        inputText
      );

      // Add copilot responses to the chat
      if (response) {
        response.data.forEach((msg) => {
          addCopilotMessage(msg);
        });
      }

      setInputText('');
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
    } finally {
      setCopilotLoading(false);
      // Force scroll to bottom after loading is complete
      setTimeout(scrollToBottom, 100);
    }
  };

  return (
    <div className='p-2'>
      <div className='relative'>
        <Textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          rows='1'
          className='custom-scrollbar max-h-[120px] min-h-10 resize-none overflow-y-auto rounded-lg border-0 bg-muted pr-12 focus-visible:ring-0 focus-visible:ring-offset-0'
          onInput={(e) => {
            const target = e.target;
            target.style.height = 'auto';
            target.style.height = `${Math.min(target.scrollHeight, 120)}px`;
          }}
          placeholder='Ask away'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendCopilotMessage();
            }
          }}
          disabled={isCopilotLoading}
        />
        <Button
          size='xs'
          variant='ghost'
          className='absolute bottom-1 right-1 text-primary hover:text-primary/90'
          onClick={() => {
            if (!isCopilotLoading) {
              handleSendCopilotMessage();
            }
          }}
          disabled={isCopilotLoading || !inputText.trim()}
        >
          {isCopilotLoading ? (
            <Loader2 className='h-4 w-4 animate-spin' />
          ) : (
            <PaperPlaneIcon className='h-4 w-4' />
          )}
        </Button>
      </div>
    </div>
  );
};

export default CopilotInput;
