import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { manage2faSetup } from '@/hooks/auth';

import TFAMethodButton from './components/TFAMethodButton';
import MobileVerificationDialog from './MobileVerificationDialog';
import PasswordVerificationDialog from './PasswordVerificationDialog';

const TwoFactorAuth = () => {
  const [loadingStates, setLoadingStates] = useState({
    totp: false,
    mobile: false,
  });
  const navigate = useNavigate();
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showMobileDialog, setShowMobileDialog] = useState(false);
  const [methodRequiringPassword, setMethodRequiringPassword] = useState(null);
  const [pendingPhoneNumber, setPendingPhoneNumber] = useState(null);

  const { tfaState, setTFAState, setTFASetupData, setValidSetupSession } =
    useUserStore(
      useShallow((state) => ({
        tfaState: state.tfaState,
        setTFAState: state.setTFAState,
        setTFASetupData: state.setTFASetupData,
        setValidSetupSession: state.setValidSetupSession,
      }))
    );

  const handleTOTPSetup = async (password = null) => {
    setLoadingStates((prev) => ({ ...prev, totp: true }));

    return toast.promise(
      manage2faSetup(tfaState.totp ? 'disable' : 'enable', 'totp', password),
      {
        loading: `${tfaState.totp ? 'Disabling' : 'Setting up'} 2FA...`,
        success: (response) => {
          if (!tfaState.totp) {
            setTFASetupData(response.qr_code_url, response.tfa_totp_secret);
            setValidSetupSession('totp', true);
            navigate('totp-setup');
          } else {
            setTFAState('totp', false);
          }
          setLoadingStates((prev) => ({ ...prev, totp: false }));
          return tfaState.totp
            ? '2FA disabled successfully'
            : '2FA setup initiated successfully';
        },
        error: (error) => {
          setLoadingStates((prev) => ({ ...prev, totp: false }));
          if (error.response?.data?.login_required) {
            setMethodRequiringPassword('totp');
            setShowPasswordDialog(true);
            return 'Password verification required';
          } else {
            return error.response?.data?.message;
          }
        },
      }
    );
  };

  const handleMobileSetup = async (phoneNumber = null, password = null) => {
    setLoadingStates((prev) => ({ ...prev, mobile: true }));
    const action = tfaState.mobile ? 'disable' : 'enable';

    return toast.promise(
      manage2faSetup(action, 'mobile', password, phoneNumber),
      {
        loading: `${
          action === 'disable' ? 'Disabling' : 'Setting up'
        } mobile authentication...`,
        success: (response) => {
          if (action === 'enable') {
            setValidSetupSession('mobile', true);
            navigate('mobile-setup');
            return 'Mobile 2FA setup initiated. Please check your phone for OTP';
          } else {
            setTFAState('mobile', false);
            return 'Mobile authentication disabled successfully';
          }
        },
        error: (error) => {
          if (error.response?.data?.login_required) {
            setMethodRequiringPassword('mobile');
            setPendingPhoneNumber(phoneNumber); // Store phone number
            setShowPasswordDialog(true);
            return 'Password verification required';
          }
          return (
            error.response?.data?.message ||
            `Failed to ${action} mobile authentication`
          );
        },
        finally: () => {
          setLoadingStates((prev) => ({ ...prev, mobile: false }));
        },
      }
    );
  };

  const handleMethodToggle = async (method) => {
    if (method === 'totp') {
      await handleTOTPSetup();
      return;
    }

    if (method === 'mobile') {
      if (tfaState.mobile) {
        // Direct disable call
        await handleMobileSetup();
      } else {
        // Show dialog for enable case
        setShowMobileDialog(true);
      }
    }
  };

  const handlePasswordVerify = async (password) => {
    if (methodRequiringPassword === 'totp') {
      await handleTOTPSetup(password);
    } else if (methodRequiringPassword === 'mobile') {
      await handleMobileSetup(pendingPhoneNumber, password);
    }
    setShowPasswordDialog(false);
    setMethodRequiringPassword(null);
    setPendingPhoneNumber(null); // Clear stored phone number
  };

  return (
    <>
      <div className='space-y-4'>
        <div>
          <h4 className='text-lg font-medium'>Two-Factor Authentication</h4>
          <p className='text-sm text-muted-foreground'>
            You can enable multiple authentication methods for enhanced
            security.
          </p>
        </div>
        <div className='space-y-6'>
          <div className='flex max-w-2xl items-center justify-between'>
            <div className='space-y-1'>
              <p className='font-medium'>Authenticator App (TOTP)</p>
              <p className='text-sm text-muted-foreground'>
                Use an authenticator app to generate one-time codes
              </p>
            </div>
            <TFAMethodButton
              isEnabled={tfaState.totp}
              isLoading={loadingStates.totp}
              onClick={() => handleMethodToggle('totp')}
            />
          </div>

          {false && (
            <div className='flex max-w-2xl items-center justify-between'>
              <div className='space-y-1'>
                <p className='font-medium'>SMS Authentication</p>
                <p className='text-sm text-muted-foreground'>
                  Receive verification codes via mobile
                </p>
              </div>
              <TFAMethodButton
                isEnabled={tfaState.mobile}
                isLoading={loadingStates.mobile}
                onClick={() => handleMethodToggle('mobile')}
              />
            </div>
          )}
        </div>
      </div>
      <MobileVerificationDialog
        isOpen={showMobileDialog}
        onClose={() => setShowMobileDialog(false)}
        onSubmit={handleMobileSetup}
      />
      <PasswordVerificationDialog
        isOpen={showPasswordDialog}
        onClose={() => setShowPasswordDialog(false)}
        onVerify={handlePasswordVerify}
      />
    </>
  );
};

export default TwoFactorAuth;
