import { useEffect, useMemo, useState } from 'react';
import useInboxStore from '@/stores/InboxStore';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { deleteAgentAndRedistribute } from '@/hooks/agent';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ButtonLoading } from '@/components/common/button/loading-button';

const DeleteAgentDialog = ({
  deleteAgentModalStatus,
  setDeleteAgentModalStatus,
  currentAgent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allocationPercentages, setAllocationPercentages] = useState({});

  const { agents, removeAgent } = useTeamMemberStore(
    useShallow((state) => ({
      agents: state.agents,
      removeAgent: state.removeAgent,
    }))
  );

  const { owners, setOwnerRoleToDeleted } = useInboxStore(
    useShallow((state) => ({
      owners: state.owners,
      setOwnerRoleToDeleted: state.setOwnerRoleToDeleted,
    }))
  );

  const filteredAgents = useMemo(
    () =>
      agents.filter(
        (agent) =>
          ['agent'].includes(agent.role) &&
          (!currentAgent || agent._id !== currentAgent._id)
      ),
    [agents, currentAgent]
  );

  const initialAllocationPercentages = filteredAgents.reduce((acc, agent) => {
    acc[agent._id] = agent.lead_allocation_percentage || 0;
    return acc;
  }, {});

  useEffect(() => {
    setAllocationPercentages(initialAllocationPercentages);
  }, [filteredAgents]);

  const handlePercentageChange = (agentId, value) => {
    if (value < 0) {
      toast.error('Allocation percentage cannot be negative');
      return;
    }

    const newAllocationPercentages = {
      ...allocationPercentages,
      [agentId]: value,
    };

    const totalPercentage = Object.values(newAllocationPercentages).reduce(
      (acc, val) => acc + Number(val),
      0
    );

    if (totalPercentage > 100) {
      toast.error('Total allocation percentage cannot exceed 100%');
      return;
    }

    setAllocationPercentages(newAllocationPercentages);
  };

  const handleClose = () => {
    setAllocationPercentages(initialAllocationPercentages);
    setDeleteAgentModalStatus(false);
  };

  const handleDelete = async () => {
    if (Object.keys(allocationPercentages).length === 0) {
      toast.error('Cannot delete the only remaning agent from the team');
      return;
    }

    setIsLoading(true);

    const agentId = currentAgent._id;
    const agentRole = currentAgent.role;

    const redistributionData =
      agentRole === 'agent'
        ? Object.entries(allocationPercentages).map(
            ([user_id, percentage]) => ({
              user_id,
              percentage,
            })
          )
        : [];

    toast.promise(deleteAgentAndRedistribute(agentId, redistributionData), {
      loading: 'Deleting agent and redistributing contacts...',
      success: (response) => {
        let customToastMessage;

        if (agentRole === 'agent') {
          const { contacts_redistribution, message } = response;
          customToastMessage = `${message} and ${contacts_redistribution.total_contacts_redistributed} contacts have been redistributed`;
        } else {
          const { message } = response;
          customToastMessage = message;
        }

        removeAgent(agentId);
        setOwnerRoleToDeleted(agentId); //updating owner also
        setDeleteAgentModalStatus(false);
        setIsLoading(false);

        return customToastMessage;
      },
      error: (error) => {
        setIsLoading(false);
        return error.response?.data?.message || 'Failed to delete agent';
      },
    });
  };

  return (
    <Dialog open={deleteAgentModalStatus} onOpenChange={handleClose}>
      {currentAgent && (
        <DialogContent className='sm:max-w-lg'>
          <DialogHeader>
            <DialogTitle>Delete Agent</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete -{' '}
              <span className='font-bold'>
                {currentAgent.first_name} {currentAgent.last_name} (
                {currentAgent.role})
              </span>{' '}
              ?
            </DialogDescription>
          </DialogHeader>
          {currentAgent.role === 'agent' && (
            <div className='space-y-4 text-sm'>
              <div>
                If the deleted agent has contacts assigned under their name,
                then those contacts will be redistributed to the following
                agents based on the respective percentage allocation.
              </div>
              <div>
                <div className='space-y-3 p-4'>
                  <div className='text-right text-sm'>
                    Remaining Percentage -{' '}
                    {100 -
                      Object.values(allocationPercentages).reduce(
                        (acc, val) => acc + Number(val),
                        0
                      )}
                    %
                  </div>
                  <ScrollArea type='always'>
                    <div className='max-h-[450px]'>
                      {filteredAgents.map((agent) => (
                        <div
                          key={agent._id}
                          className='mb-3 flex items-center gap-4 px-1'
                        >
                          <div className='flex-1'>
                            {agent.first_name} {agent.last_name}
                          </div>
                          <Input
                            type='number'
                            min='0'
                            value={allocationPercentages[agent._id]}
                            onChange={(e) =>
                              handlePercentageChange(
                                agent._id,
                                Number(e.target.value)
                              )
                            }
                            className='w-24'
                          />
                          <span>%</span>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </div>
            </div>
          )}
          <DialogFooter className='mt-5'>
            <div className='flex w-full flex-row items-center justify-end'>
              <div className='flex space-x-2'>
                <Button variant='secondary' onClick={handleClose}>
                  Cancel
                </Button>
                {isLoading ? (
                  <ButtonLoading />
                ) : (
                  <Button onClick={handleDelete} variant='destructive'>
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DeleteAgentDialog;
