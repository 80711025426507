import { useState } from 'react';

const CopilotFeatures = () => {
  const [showFeatures, setShowFeatures] = useState(true);

  const features = [
    {
      title: 'I can help you craft the right message',
    },
    {
      title: 'I can find it using past closed-won deals',
    },
    {
      title: 'Our conversation stays private to you',
    },
    {
      title: 'Leverage me to grow your revenue',
    },
  ];

  return (
    <>
      <div className='flex justify-center'>
        {showFeatures ? (
          <div className='rounded-md bg-secondary/30 px-2 pt-2'>
            <div className='grid grid-cols-2 gap-1'>
              {features.map((feature, index) => (
                <div
                  key={index}
                  className='cursor-default rounded-lg bg-secondary/50 p-4 text-left text-xs text-muted-foreground'
                >
                  {feature.title}
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowFeatures(false)}
              className='w-full py-2 text-center text-xs text-muted-foreground transition-colors hover:text-foreground'
            >
              Hide
            </button>
          </div>
        ) : (
          <div
            onClick={() => setShowFeatures(true)}
            className='cursor-pointer rounded-md p-2 text-center text-xs text-muted-foreground hover:bg-secondary/70'
          >
            See what I could help you with
          </div>
        )}
      </div>
      <div className='my-4 border border-t' />
    </>
  );
};

export default CopilotFeatures;
