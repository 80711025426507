import * as React from 'react';

const CopilotIcon = (props) => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 0.916687C11.5063 0.916687 11.9167 1.32709 11.9167 1.83335V2.75002H16.5C17.5125 2.75002 18.3333 3.57083 18.3333 4.58335V11C18.3333 11.6786 17.9647 12.2711 17.4167 12.5881V13.3703L18.9815 14.9352C19.3395 15.2932 19.3395 15.8736 18.9815 16.2315C18.6235 16.5895 18.0431 16.5895 17.6852 16.2315L17.1243 15.6707C16.308 18.2762 13.8748 20.1667 11 20.1667C8.12524 20.1667 5.69203 18.2762 4.87572 15.6707L4.31485 16.2315C3.95687 16.5895 3.37647 16.5895 3.01849 16.2315C2.6605 15.8736 2.6605 15.2932 3.01849 14.9352L4.58333 13.3703L4.58333 12.5881C4.03535 12.2711 3.66667 11.6786 3.66667 11V4.58335C3.66667 3.57083 4.48748 2.75002 5.5 2.75002H10.0833V1.83335C10.0833 1.32709 10.4937 0.916687 11 0.916687ZM5.5 4.58335V11H16.5V4.58335H5.5Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.25 6.41669C8.75626 6.41669 9.16667 6.82709 9.16667 7.33335V8.25002C9.16667 8.75628 8.75626 9.16669 8.25 9.16669C7.74374 9.16669 7.33333 8.75628 7.33333 8.25002V7.33335C7.33333 6.82709 7.74374 6.41669 8.25 6.41669ZM13.75 6.41669C14.2563 6.41669 14.6667 6.82709 14.6667 7.33335V8.25002C14.6667 8.75628 14.2563 9.16669 13.75 9.16669C13.2437 9.16669 12.8333 8.75628 12.8333 8.25002V7.33335C12.8333 6.82709 13.2437 6.41669 13.75 6.41669Z'
      fill='white'
    />
  </svg>
);
export default CopilotIcon;
