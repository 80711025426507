import { useEffect, useRef } from 'react';
import useContactStore from '@/stores/ContactStore';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';

import { Avatar } from '../ui/avatar';
import CopilotFeatures from './copilot/CopilotFeatures';
import CopilotIcon from './copilot/CopilotIcon';
import CopilotInput from './copilot/CopilotInput';
import CopilotMessageList from './copilot/CopilotMessageList';

function CoPilotButton({ sidebarOpen, addMessage }) {
  // Refs
  const scrollAreaRef = useRef(null);
  const previousScrollHeight = useRef(0);

  // Get copilotMessages for conditional rendering
  const {
    copilotMessages,
    isCopilotOpen,
    setCopilotOpen,
    isCopilotLoading,
    profile_picture_url,
    fullname,
    ig_username,
  } = useContactStore(
    useShallow((state) => ({
      copilotMessages: state.copilotMessages,
      isCopilotOpen: state.isCopilotOpen,
      setCopilotOpen: state.setCopilotOpen,
      isCopilotLoading: state.isCopilotLoading,
      profile_picture_url: state.profile_picture_url,
      fullname: state.fullname,
      ig_username: state.ig_username,
    }))
  );

  // Scroll functions
  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  };

  // Handle scroll position when messages change
  useEffect(() => {
    if (scrollAreaRef.current) {
      const isAtBottom =
        scrollAreaRef.current.scrollHeight - scrollAreaRef.current.scrollTop <=
        scrollAreaRef.current.clientHeight + 100; // 100px threshold

      if (isAtBottom || previousScrollHeight.current === 0) {
        scrollToBottom();
      } else {
        // Calculate the height difference of the scroll content
        const scrollHeightDifference =
          scrollAreaRef.current.scrollHeight - previousScrollHeight.current;

        // Set the scroll position to maintain the current visible position
        scrollAreaRef.current.scrollTop += scrollHeightDifference;
      }

      // Save the new scroll height
      previousScrollHeight.current = scrollAreaRef.current.scrollHeight;
    }
  }, [isCopilotLoading, copilotMessages]);

  // Scroll to bottom when popover opens
  useEffect(() => {
    if (isCopilotOpen) {
      // Reset previous scroll height when opening
      previousScrollHeight.current = 0;

      // Use a small timeout to ensure the DOM is fully rendered
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [isCopilotOpen]);

  return (
    <Popover open={isCopilotOpen} onOpenChange={setCopilotOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='default'
          size='icon'
          className={`pointer-events-auto fixed bottom-6 z-[999] h-12 w-12 rounded-full bg-primary text-primary-foreground shadow-lg hover:bg-primary hover:brightness-90 ${
            sidebarOpen ? 'right-[396px]' : 'right-6'
          }`}
        >
          <CopilotIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className='flex h-[650px] w-[448px] flex-col bg-background p-0'
        align='end'
        side='top'
      >
        <div className='flex flex-1 flex-col overflow-hidden'>
          <ScrollArea className='flex-1' ref={scrollAreaRef}>
            <div className='flex flex-col'>
              {/* Header */}
              <div className='flex flex-col items-center space-y-2 p-6'>
                <div className='flex h-12 w-12 items-center justify-center rounded-full bg-primary'>
                  <CopilotIcon />
                </div>
                <h3 className='font-medium text-foreground'>Revio CoPilot</h3>
                {copilotMessages.length === 0 ? (
                  <p className='text-center text-sm text-muted-foreground'>
                    Think of me as a coach and I&apos;m here to help you close
                  </p>
                ) : (
                  <div className='flex items-center gap-2 px-3'>
                    <span className='text-sm text-muted-foreground'>
                      I&apos;ve all the context for chat with{' '}
                      {fullname || ig_username}
                    </span>
                    <Avatar className='h-6 w-6'>
                      <img
                        src={profile_picture_url || '/default-avatar.png'}
                        alt={
                          fullname?.split(' ')[0] || ig_username || 'Contact'
                        }
                      />
                    </Avatar>
                  </div>
                )}
              </div>

              {/* Features */}
              {copilotMessages.length === 0 && <CopilotFeatures />}

              {/* Chat Section - Messages */}
              <div className='px-6 pb-6'>
                <CopilotMessageList
                  isLoading={isCopilotLoading}
                  setIsOpen={setCopilotOpen}
                  addMessage={addMessage}
                />
              </div>
            </div>
          </ScrollArea>

          {/* Input Section - Fixed at bottom */}
          <CopilotInput scrollToBottom={scrollToBottom} />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default CoPilotButton;
