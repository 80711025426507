import { Loader2 } from 'lucide-react';

import { Button } from '@/components/ui/button';

const TFAMethodButton = ({ isEnabled, isLoading, onClick }) => (
  <Button
    onClick={onClick}
    variant={isEnabled ? 'destructive' : 'outline'}
    disabled={isLoading}
    className='w-[100px]'
  >
    {isLoading ? (
      <Loader2 className='h-4 w-4 animate-spin' />
    ) : isEnabled ? (
      'Disable'
    ) : (
      'Enable'
    )}
  </Button>
);

export default TFAMethodButton;
