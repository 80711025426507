import axios from 'axios';

import { getToken } from './utils';

// default
// axios.defaults.baseURL = import.meta.env.VITE_API_URL;

// content type

// Add a request interceptor to handle authorization
axios.interceptors.request.use(
  (config) => {
    // Check if it's an authenticated route

    if (config.authRoute) {
      // Retrieve the token from cookies or wherever you store it
      const token = getToken();

      if (!token) {
        // If the token is not present, reject the request and handle it
        return Promise.reject({ message: 'Token not present' });
      }

      // Set the Authorization header for authenticated routes
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    console.log('interceptor error -> ', error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.response.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = error;
        break;
      case 422:
        message = error.response.data.message;
        break;
      default:
        message = error;
    }
    return Promise.reject(message);
  }
);

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params, authRoute = false) => {
    return axios.get(url, { params, authRoute });
  };

  /**
   * post given data to url
   */
  create = (
    url,
    data,
    authRoute = false,
    clientId = false,
    contentType = 'application/json'
  ) => {
    const headers = {
      'Content-Type': contentType,
      ...(clientId && { 'X-CLIENT-ID': clientId }),
    };

    return axios.post(url, data, { headers, authRoute });
  };

  /**
   * Updates data
   */
  update = (url, data, authRoute = false) => {
    return axios.patch(url, data, { authRoute });
  };

  /**
   * Overwrites data
   */
  overwrite = (url, data, authRoute = false) => {
    return axios.put(url, data, { authRoute });
  };

  /**
   * Delete
   */
  delete = (url, data, authRoute = false) => {
    return axios.delete(url, { data: data, authRoute });
  };
}

export { APIClient };
