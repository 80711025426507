import {
  changePasswordEndpoint,
  login,
  manage2fa,
  register,
  switch2fa,
  verify2fa,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function loginUser(email, password) {
  return apiClient.create(login, {
    email,
    password,
  });
}

export function registerUser(email, first_name, last_name, password) {
  return apiClient.create(register, {
    first_name,
    last_name,
    email,
    password,
  });
}

export function changePassword(currentPassword, newPassword) {
  return apiClient.create(
    changePasswordEndpoint,
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
    true
  );
}

export function manage2faSetup(
  action,
  method,
  password = null,
  mobileNumber = null
) {
  const data = {
    action,
    method,
  };

  if (password) {
    data.password = password;
  }

  if (method === 'totp') {
    return apiClient.create(manage2fa, data, true);
  }

  if (method === 'mobile') {
    if (action === 'enable' && mobileNumber) {
      data.value = mobileNumber;
    }
    return apiClient.create(manage2fa, data, true);
  }
}

export function verify2faSetup(otp, isRecoveryCode = null) {
  const data = {
    code: otp,
  };
  if (isRecoveryCode) {
    data.recovery = isRecoveryCode;
  }
  return apiClient.create(verify2fa, data);
}

export function switch2faMethodOrSendOTP(method) {
  return apiClient.create(switch2fa, { method });
}
